import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import SwitchControlled from '../switch/Switch';
import Cookies from 'js-cookie';
import './BatchSex.scss'


export default function Servers({
    listApiUrl = 'https://smsauth.messagemate.at/api/listAmateursex.php'
}) {
    const [rows, setRows] = React.useState([]);

    const fetchData = async () => {
        try {
            // Fetch activity
            const response = await fetch(listApiUrl);
            if (response.ok) {
                const data = await response.json();
                setRows(data.data);
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
  };
  
    React.useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const user = Cookies.get('username');

    const handleToggle = async (id, isChecked) => {
        const isActive = isChecked ? 1 : 0;
        const apiUrl = `https://smsauth.messagemate.at/api/setIsActiveAmateursex.php?id=${id}&user=${user}&is_active=${isActive}`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
    
            if (data.success) {
                console.log(data);
                const updatedRows = rows.map(row => {
                    if (row.id === id) {
                        return { ...row, is_running: isChecked ? '1' : '0', remark: data.remark };
                    }
                    return row;
                });
                setRows(updatedRows);
            } else {
                console.error(`Error ${data.errorcode}: ${data.errormessage}`);
            }
        } catch (error) {
            console.error('Error updating server status:', error);
        }
    };
    

    const getRowClassName = (params) => {
        const isRunning = params.row.is_active === '1';
    
        return isRunning ? 'active-highlight' : '';
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'sid', headerName: 'SID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'is_active', headerName: 'Running', type: 'number', width: 100 },
        { field: 'remark', headerName: 'Remark', width: 350 },
        {
            field: 'actions',
            headerName: 'Start/Stop',
            width: 180,
            renderCell: (params) => {
                const isRunning = params.row.is_active === '1';

                return (
                    <SwitchControlled
                        isChecked={isRunning}
                        disabled={false}
                        onToggle={(newChecked) => handleToggle(params.id, newChecked)}
                    />
                );
            }
        }
    ];

    return (
        <div>
            <h2 style={{ marginBottom: '15px'}}>Amateur Sex List</h2>
            <div style={{ height: 631, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowClassName={getRowClassName}
                    initialState={{
                        pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
}