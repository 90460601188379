import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const ReceiversGroupDropdown = ({ onChange, value, error, helperText }) => {

    const [groups, setGroups] = useState([]);
    // const [selectedGroup, setSelectedGroup] = useState('');

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await fetch('https://smsauth.messagemate.at/goip_api/listGroups.php');
                const data = await response.json();
                if (data && data.data) {
                    setGroups(data.data);
                }
            } catch (error) {
                console.error('Error while fetching data:', error);
            }
        }; 

        fetchGroups();
    }, []);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedGr = groups.find(p => p.id === selectedId)?.name;

        // setSelectedGroup(selectedId);

        if (onChange) {
            onChange(selectedId, selectedGr);;
        }
    };

    return (
        <FormControl sx={{ width: 234 }} error={error} >
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
            labelId="group-select-label"
            id="group-select"
            // value={selectedGroup}
            label="Group"
            value={value}
            onChange={handleChange}
        >
            {groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
                {group.name}
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default ReceiversGroupDropdown;