import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

export default function ButtonsGroup({ setFetchedData, setLoading, setActiveButton, url }) {
  const [activeButton, internalSetActiveButton] = React.useState(1);

  const fetchSms = (id) => {
        const baseURL = `${url}?timespankey=${id}`;

        setLoading(true);

        fetch(baseURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server responded with status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            if (data['errorcode'] !== "200") {
                console.log(data['error']);
            } else {
                setFetchedData(data.data);
            }
        })
        .catch((error) => {
            console.error("Error fetching SMS:", error);
        })
        .finally(() => {
            setLoading(false);
        });

        internalSetActiveButton(id);
        setActiveButton(id);
  };

  return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                m: 1,
            },
            '& .MuiButton-root': {
                color: 'rgb(84, 95, 100)',
                border: '1px solid rgb(84, 95, 100)',
            },
        }}
    >
      <ButtonGroup variant="outlined" aria-label="button group">
            {[
                { label: 'Last Hour', id: 1 },
                { label: 'Last 6 Hours', id: 6 },
                { label: 'Last 12 Hours', id: 12 },
                { label: 'Last 24 Hours', id: 24 },
                { label: 'Today', id: 99 },
            ].map((button) => (
                <Button
                    key={button.id}
                    onClick={() => fetchSms(button.id)}
                    style={{
                    backgroundColor: activeButton === button.id ? 'rgba(62, 138, 172, 0.28)' : '',
                    }}
                >
                    {button.label}
                </Button>
            ))}
      </ButtonGroup>
    </Box>
  );
}
