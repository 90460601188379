import * as React from 'react';
// import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function NumbersList() {

    const [rows, setRows] = React.useState([]);

    const navigate = useNavigate();



    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://smsauth.messagemate.at/api/listAllNumbers.php');
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                    console.log(data)
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    

    const handleEdit = (id) => {
        navigate(`/numbers-edit/${id}`);
    };

    const handleDelete = (id) => {
        fetch(`https://smsauth.messagemate.at/api/deletenumber.php?id=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.data === 1) {
                const newRows = rows.filter(row => row.id !== id);
                setRows(newRows);
            }
        });
    };


    function DelButtonModal({ id }) {
        const [open, setOpen] = React.useState(false);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
        const handleClickOpen = () => {
            setOpen(true);
        };
    
        const handleClose = () => {
            setOpen(false);
        };

        function combinedClickHandler() {
            handleClose();
            handleDelete(id);
        }
    
        return (
            <div>
            <Button variant="outlined" size="small" color="error" onClick={handleClickOpen}>
                Delete
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                {"Are you sure?"}
                </DialogTitle>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Disagree
                </Button>
                <Button onClick={combinedClickHandler} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
  
    const columns = [
        { field: 'start_range', headerName: 'Start range', type: 'number', width: 130 },
        { field: 'end_range', headerName: 'End range', type: 'number', width: 130 },
        { field: 'mcc', headerName: 'mcc', type: 'number', width: 100 },
        { field: 'mnc', headerName: 'mnc', type: 'number', width: 100 },
        { field: 'vpc', headerName: 'vpc', width: 100 },
        { field: 'iso2', headerName: 'iso2', width: 50 },
        { field: 'country', headerName: 'country', type: 'number', width: 100 },
        { field: 'is_active', headerName: 'active', width: 100 },
        {
            field: 'isd',
            headerName: 'isd',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            type: 'number',
            width: 50,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 180,
          renderCell: (params) => {
            return (
              <>
                <Button size="small" variant="outlined" style={{marginRight: 10}} onClick={() => handleEdit(params.row.id)}>Edit</Button>
                <DelButtonModal id={params.row.id} />
              </>
            );
          }
        }
    ];
  
  
    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
      );
  };