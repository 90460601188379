import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, Button, CircularProgress, Box } from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
import './P2PRevenue.scss';

export default function P2PRevenue() {
    const location = useLocation();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [fetchedData, setFetchedData] = useState([]);
    const [apiLink, setApiLink] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (location.pathname.includes('/p2p/daily-revenue')) {
            updateEndDate(startDate);
        } else if (location.pathname.includes('/p2p/monthly-revenue')) {
            setApiLink('https://smsauth.messagemate.at/asmsc_api/monthlyRevenueCountryCustomer.php');
        }
    }, [location.pathname, startDate]);

    useEffect(() => {
        fetchData();
    }, [apiLink]);

    const setToday = () => {
        const today = new Date();
        setStartDate(today);
        updateEndDate(today);
    };

    const setYesterday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(yesterday);
        updateEndDate(yesterday);
    };

    const updateEndDate = (start) => {
        const nextDay = new Date(start);
        nextDay.setDate(nextDay.getDate() + 1);
        setEndDate(nextDay);
        setApiLink(`https://smsauth.messagemate.at/asmsc_api/dailyRevenueCountryCustomer.php?startdate=${start.toISOString().split('T')[0]}&enddate=${nextDay.toISOString().split('T')[0]}`);
    };

    const getFormattedDate = () => {
        const date = new Date();
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getFileNamePrefix = () => {
        if (location.pathname.includes('/p2p/daily-revenue')) {
          return 'p2pdaily';
        } else if (location.pathname.includes('/p2p/monthly-revenue')) {
          return 'p2pmonthly';
        }
        return 'p2p';
    };

    const exportXLS = () => {
        const todayDate = getFormattedDate();
        const fileNamePrefix = getFileNamePrefix();
        const fileName = `${fileNamePrefix}_${todayDate}_report.xlsx`;

        const headers = columns.map(col => col.headerName);
        const dataForExcel = [headers, ...rows.map(row => columns.map(col => row[col.field]))];

        const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DataGrid');

        XLSX.writeFile(workbook, fileName);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiLink);
            if (response.ok) {
                const data = await response.json();
                setFetchedData(data.data);
                console.log(data);
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [apiLink]);

    const rows = fetchedData.map((row, index) => {
        return { id: index, ...row };
    });

    let columns = [
        { field: 'CountryName', headerName: 'Country', width: 160 },
        { field: 'Customer', headerName: 'Customer', width: 160 },
        { field: 'AnzahlSMS', headerName: 'SMS', type: 'number', width: 90 },
        { field: 'VendorCost', headerName: 'Vendor Cost', type: 'number', width: 160 },
        { field: 'CustomerCost', headerName: 'Customer Cost', type: 'number', width: 160 },
        { field: 'Margin', headerName: 'Margin', type: 'number', width: 160 }
    ];

    if (location.pathname.includes('/p2p/daily-revenue')) {
        columns = [{ field: 'FormatedDate', headerName: 'Date', width: 160 }, ...columns];
    } else if (location.pathname.includes('/p2p/monthly-revenue')) {
        const yearColumn = { field: 'Year', headerName: 'Year', width: 100 };
        const monthColumn = { field: 'Month', headerName: 'Month', type: 'number', width: 80 };
        columns = [yearColumn, monthColumn, ...columns];
    }

    const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
        flexDirection: 'column',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    const CustomLoadingOverlay = () => {
        return (
            <StyledGridOverlay>
                <CircularProgress />
            </StyledGridOverlay>
        );
    };

    return (
        <div>
            <div className='top-block'>
                <div className='top-block__left'>
                    {location.pathname.includes('/p2p/daily-revenue') ? (
                        <>
                            <div style={{ marginBottom: '10px' }}>
                                <Button variant="outlined" onClick={setYesterday}>Yesterday</Button>
                                <Button variant="outlined" onClick={setToday} style={{ marginLeft: '10px', marginRight: '10px' }}>Today</Button>
                            </div>
                            
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start"
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        updateEndDate(date);
                                    }}
                                    renderInput={(props) => <TextField {...props} />}
                                    format="yyyy-MM-dd"
                                />
                            </LocalizationProvider>
                        </>
                    ) : (
                        <div className="placeholder"></div>
                    )}
                </div>
                <div className='top-block__export'>
                    <Button variant="outlined" onClick={exportXLS}>Export</Button>
                </div>
            </div>

            <div className='data-block' style={{ height: 631, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    components={{
                        LoadingOverlay: CustomLoadingOverlay,
                    }}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                    }}
                    pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
}
