import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// import { useNavigate } from 'react-router-dom';
import P2AReceiverProviderDropdown from "../p2aReceiverProviderDropdown/P2AReceiverProviderDropdown";
import P2AReceiverGroupDropdown from "../p2aReceiverGroupDropdown/P2AReceiverGroupDropdown";





export default function ReceiverAdd() {
    const [no, setNo] = useState("");
    const [name, setName] = useState("");
    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [providerId, setProviderId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [info, setInfo] = useState("");

    const [errors, setErrors] = useState({});

    const handleProviderChange = (id) => {
        setProviderId(id);
    };
    const handleGroupChange = (id) => {
        setGroupId(id);
    };

    // Textfield validate
    const validateRequiredText = (value) => {
        return value.trim() ? "" : "This field is required.";
    };

    // Dropdown validate
    const validateDropdownSelection = (value) => {
        return value ? "" : "Selection is required.";
    };

    // Validation start_range and end_range
    const validateRange = (startRange, endRange) => {
        const errors = { startRange: "", endRange: "" };
        const start = parseInt(startRange, 10);
        const end = parseInt(endRange, 10);
        if (!startRange || isNaN(start)) errors.startRange = "Start range must be a number.";
        if (!endRange || isNaN(end)) errors.endRange = "End range must be a number.";
        if (start > end) {
            errors.startRange = "Start range must be less than end range.";
            errors.endRange = "End range must be greater than start range.";
        }
        return errors;
    };

  

    const handleSubmit = async () => {

        const validationErrors = {
            no: validateRequiredText(no),
            name: validateRequiredText(name),
            providerId: validateDropdownSelection(providerId),
            groupId: validateDropdownSelection(groupId)
        };
    
        const rangeErrors = validateRange(startRange, endRange);
    
        
        const allErrors = {
            ...validationErrors,
            ...rangeErrors,
        };
    
        
        const hasErrors = Object.values(allErrors).some(error => error);
    
        if (hasErrors) {
            setErrors(allErrors);
            return;
        }
    
        setErrors({});

        const formData = new FormData();

        formData.append('no', no);
        formData.append('name', name);
        formData.append('providerId', providerId);
        formData.append('groupid', groupId);
        formData.append('info', info);
        formData.append('start_range', startRange)
        formData.append('end_range', endRange)
        formData.append('providerid', providerId)

        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/addReceiver.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // navigate('/p2a/receiver');
                    setNo("");
                    setName("");
                    setStartRange("");
                    setEndRange("");
                    setProviderId("");
                    setGroupId("");
                    setInfo("");
                } else {
                    console.log(data)
                }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };

  
    return (
      <Stack>
        <h2 className='header'>Receiver ADD</h2>
        <div className='inputs-wrapper'>
            <TextField
                error={!!errors.no}
                helperText={errors.no}
                id="no"
                label="No"
                variant="outlined"
                required
                onChange={(e) => setNo(e.target.value)}
            />
        </div>
        <div className='inputs-wrapper'>
            <TextField
                error={!!errors.name}
                helperText={errors.name}
                id="name"
                label="NAME"
                variant="outlined"
                required
                onChange={(e) => setName(e.target.value)}
            />
        </div>
        <div className='inputs-wrapper'>
            <P2AReceiverGroupDropdown
                className="custom-input"
                value={groupId}
                onChange={handleGroupChange}
                error={!!errors.groupId}
                helperText={errors.groupId}
            />
        </div>
        <div className='inputs-wrapper'>
            <TextField
                sx={{ width: 274 }}
                id="info"
                label="Info"
                multiline
                rows={4}
                onChange={(e) => setInfo(e.target.value)}
            />
        </div>
        <div className='inputs-wrapper'>
            <TextField
                error={!!errors.startRange}
                helperText={errors.startRange}
                id="start_range"
                label="Start range"
                variant="outlined"
                required
                value={startRange}
                onChange={(e) => setStartRange(e.target.value)}
            />
            <TextField
                error={!!errors.endRange}
                helperText={errors.endRange}
                id="end_range"
                label="End range"
                variant="outlined"
                required
                value={endRange}
                onChange={(e) => setEndRange(e.target.value)}
            />
        </div>
        <div className='inputs-wrapper'>
            <P2AReceiverProviderDropdown
                className="custom-input"
                value={providerId}
                onChange={handleProviderChange}
                error={!!errors.providerId}
                helperText={errors.providerId}
            />
        </div>
        <div className='inputs-wrapper'>
            <Button variant="outlined" onClick={handleSubmit}>save</Button>
        </div>
      </Stack>
    );
}

