import React from 'react';
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
// import Cookies from 'js-cookie';



export default function Admin() {






return (
    <div></div>
)

}