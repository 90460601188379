import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'; 

import '../sendMobile/SendMobile.scss';

import SendDirectIdDbDropdown from "../send-direct-idDB-dropdown/SendDirectIdDbDropdown";
import SendDirectIsdDropdown from "../send-direct-isd-dropdown/SendDirectIsdDropdown";
import SendDirectScvInput from "../send-direct-csv-input/SendDirectScvInput";
import SendDirectMessageDropdown from "../send-direct-message-dropdown/SendDirectMessageDropdown";
import MobileGroupsDropdown from "../mobile-groups-dropdown/MobileGroupsDropdown";
import MobileDeviceDropdown from '../mobile-device-dropdown/MobileDeviceDropdown';
import { set } from 'date-fns';


 
export default function SendDirect() {

    const initialUuid = uuidv4(); // Save initial UUID
    const initialUuidRef = useRef(uuidv4()); // Creates and stores the UUID value when the first render is made
    const [runid, setRunid] = useState(initialUuidRef.current);
    const [campaign, setCampaign] = useState("");
    const [groupId, setGroupId] = useState("");
    //const [deviceId, setDeviceId] = useState("");
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([]); // New state for selected device IDs
    const [sendto, setSendto] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedIsd, setSelectedIsd] = useState("")
    const [csvData, setCsvData] = useState([]);
    //const [hours, setHours] = useState("");
    const [lowerdeltatime, setLowerdeltatime] = useState("");
    const [upperdeltatime, setUpperdeltatime] = useState("");
    const [runs, setRuns] = useState("");
    const [message, setMessage] = useState("");
    const [remainingChars, setRemainingChars] = useState(160);
    const [numbersCount, setnumbersCount] = useState(0);
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [formKey, setFormKey] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [errors, setErrors] = useState({});

    const location = useLocation();

    const prevCampaignDataRef = useRef();

    useEffect(() => {
    const { campaignData } = location.state || {};

    if (campaignData && prevCampaignDataRef.current !== campaignData) {
        console.log(location.state);
        console.log(campaignData);

        setCampaign(campaignData.campaign.campaignname);
        //setProviderId(campaignData.provider.id);
        //setProviderProv(campaignData.provider.prov);
        //setGoipid(campaignData.goip.goipid);

        const newRunId = `${campaignData.provider.prov}_${campaignData.goip.goipid}_${initialUuidRef.current}`;
        setRunid(newRunId);

        if (Array.isArray(campaignData.numbers) && campaignData.numbers.length > 0) {
        const numbersString = campaignData.numbers.join(', ');
        setSendto(numbersString);
        updateNumbersCount(numbersString);
        }

        prevCampaignDataRef.current = campaignData;
    }
    }, [location]);

    const handleSendToChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9, ]*$/;
    
        if (regex.test(value)) {
            setSendto(value);
            const numbersArray = value.replace(/\s/g, '').split(',').filter(num => num !== '');
            setnumbersCount(numbersArray.length);
        }
    };

    const handleValidCsv = (data) => {
        setCsvData(data);
    };

    const handleSelectCsv = () => {
        const currentNumbers = sendto ? sendto.split(',').map(num => num.trim()) : [];
        const allNumbers = Array.from(new Set([...currentNumbers, ...csvData])).join(',');
        setSendto(allNumbers);
    };

    const [selectedNumbers, setSelectedNumbers] = useState([]);

    const handleIdsSelected = (numbers) => {
        setSelectedNumbers(numbers);
        setSelectedIsd("");
    };

    const handleIsdsSelected = (numbers) => {
        setSelectedNumbers(numbers);
        setSelectedId("");
    };

    const handleAddFromDb = () => {
        const currentNumbersArray = sendto.split(', ').filter(num => num.trim() !== '');
        const updatedNumbers = Array.from(new Set([...currentNumbersArray, ...selectedNumbers])).join(',');
    
        setSendto(updatedNumbers);
        updateNumbersCount(updatedNumbers);
    };

    const updateNumbersCount = (numbersString) => {
        const numbersArray = numbersString.replace(/\s/g, '').split(',').filter(num => num !== '');
        setnumbersCount(numbersArray.length);
    };

    
    const handleGroupChange = (groupId, name) => {
        setGroupId(groupId);
        //setProviderProv(prov);
        //setRunid(`${prov}_${goipid ? goipid + '_' : ''}${initialUuid}`);
        setErrors({ ...errors, groupId: validateDropdown(groupId, "Mobile Group") });
      };
      
      //const handleDeviceIdChange = (deviceIdValue) => {
      //  setDeviceId(deviceIdValue);
        //setRunid(`${providerProv}_${goipIdValue}_${initialUuid}`);
      //  setErrors({ ...errors, deviceId: validateDropdown(deviceId, "Mobile Device") });
      //}; 
    const handleDeviceIdChange = (deviceIds) => {
        setSelectedDeviceIds(deviceIds);
        setErrors({ ...errors, device: validateDropdown(deviceIds, "Mobile Device") }); // Update error handling
    };
    
    const handleMessageSelect = (messageData) => {
        const text = messageData.text || "";
        console.log(text)
        if (text.length <= 160) {
            setMessage(text);
            setSelectedMessageId(messageData.id);
            setRemainingChars(160 - text.length);
            setErrors({ ...errors, message: validateDropdown(text, "SMS Template") });
            console.log(text)
        }
    };
    
    const handleMessageChange = (event) => {
        const input = event.target.value;
        if (input.length <= 160) {
            setMessage(input);
            setRemainingChars(160 - input.length);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    const handleSubmit = async () => {
        const campaignError = validateCampaign(campaign);
        const groupIdError = validateDropdown(groupId, "Mobile Group");
        //const deviceIdError = validateDropdown(deviceIds, "Mobile Device");
        const devicesError = selectedDeviceIds.length > 0 ? "" : "At least one device is required.";
        const lowerdeltatimeError = validateDigitInput(lowerdeltatime);
        const upperdeltatimeError = validateDigitInput(upperdeltatime);
        //const hoursError = validateDigitInput(hours);
        const messageError = validateDropdown(message);
        const numbersError = numbersCount > 0 ? "" : "At least one number is required.";
        const runsError = validateDigitInput(runs);

        if (campaignError || groupIdError || upperdeltatimeError || lowerdeltatimeError || messageError || numbersError || devicesError || runsError) {
            //console.log("Validation errors:", errors); // Log the errors
            setErrors({
                campaign: campaignError,
                group: groupIdError,
                device: devicesError,
                runs: runsError,
                lowerdeltatime: lowerdeltatimeError,
                upperdeltatime: upperdeltatimeError,
                //hours: hoursError,
                message: messageError,
                numbers: numbersError
            });
            return;
        }

    
        const formData = new FormData();
        formData.append('numbers', sendto.trim());
        formData.append('run_id', runid);
        formData.append('campaign', campaign);
        formData.append('groupid', groupId);
        //formData.append('deviceid', deviceId);
        selectedDeviceIds.forEach(deviceId => formData.append('device_ids[]', deviceId)); // Append device IDs
        formData.append('upperdeltatime', upperdeltatime);
        formData.append('lowerdeltatime', lowerdeltatime);
        //formData.append('hours', hours);
        formData.append('message', selectedMessageId);
        formData.append('runs', runs);
        
        try {
            //console.log("Submitting form...");
            //console.log("formData:", formData); // Inspect the formData object
            const response = await fetch('https://smsauth.messagemate.at/goip_api/mobilerun.php', {
                method: 'POST',
                body: formData,
            });
            
            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.error || `HTTP error! status: ${response.status}`;
                throw new Error(errorMessage);
            }
            const result = await response.json();
            console.log(result);

            setRunid(uuidv4());
            setCampaign("");
            setSendto("");
            setSelectedId("");
            setSelectedIsd("");
            setMessage(""); 
            //setHours("");
            setLowerdeltatime("");
            setRuns("");
            setUpperdeltatime("");
            setnumbersCount(0);
            setRemainingChars(160);
            setSelectedMessageId("");
            setIsModalOpen(true);
            setFormKey(prevKey => prevKey + 1);

        } catch (error) {
            console.error("There was an error sending the data", error);
            setErrors({ ...errors, apiError: error.message });
        }
    };

     // Validation function -------------------
    const validateDropdown = (value, field) => {
        if (!value || value.length === 0) return `${field} is required.`; // Check for empty array
        return "";
    };

   

    const validateCampaign = (value) => {
        if (!value.trim()) return "Campaign is required.";
        return "";
    };

    const handleCampaignChange = (e) => {
        setCampaign(e.target.value);
        setErrors({ ...errors, campaign: validateCampaign(e.target.value) });
    };

   // const validateDropdown = (value, field) => {
   //     if (!value) return `${field} is required.`;
   //     return "";
   // };

    const validateDigitInput = (value) => {
        if (!value) return "Field is required.";
        if (isNaN(value) || parseInt(value) < 1) return "Value must be a number greater than 0.";
        return "";
      };


    function FormSubmittedModal({ open, onRefresh }) {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
        const handleOk = () => {
            onRefresh();
        };
    
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleOk}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Campaign Created"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleOk} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <Stack spacing={5} sx={{ width: 300 }} key={formKey}>
            <div className='send-inputs-wrapper'>
                <div className='inputs-halfwidth-column'>
                    <TextField fullWidth disabled id="runid" label="Run ID" variant="outlined" value={runid} required />
                </div>
                <div className='inputs-halfwidth-column'>
                    <TextField 
                        fullWidth 
                        error={!!errors.campaign}
                        helperText={errors.campaign}
                        id="campaign" 
                        label="Mobile Campaign" 
                        variant="outlined" 
                        value={campaign}
                        onChange={handleCampaignChange} 
                        required 
                    />
                </div>
            </div>
            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <TextField
                    id="numbers-multiline"
                    label="Number(s)"
                    multiline
                    rows={8}
                    value={sendto}
                    onChange={handleSendToChange}
                    error={!!errors.numbers}
                    helperText={errors.numbers || `${numbersCount} numbers selected`}
                    />
                </div>
                <div className='send-inputs-column'>
                    <div className='send-inputs-row send-inputs-row__underline'>
                        <Button variant="outlined" className="direct-button" onClick={handleAddFromDb}>Select from DB</Button>
                        <SendDirectIdDbDropdown
                            selectedValue={selectedId}
                            onIdsSelected={handleIdsSelected}
                            onValueChange={setSelectedId}
                        />
                        <SendDirectIsdDropdown
                            selectedValue={selectedIsd}
                            onIsdsSelected={handleIsdsSelected}
                            onValueChange={setSelectedIsd}
                        />
                    </div>

                    <div className='send-inputs-row'>
                        <Button variant="outlined" className="direct-button" onClick={handleSelectCsv}>Select from CSV</Button>
                        <SendDirectScvInput className="file-input" onValidCsv={handleValidCsv} />
                    </div>
                </div>
            </div>
            
            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <MobileGroupsDropdown 
                        className="custom-input" 
                        selectedMobileGroupProp={groupId}
                        onChange={handleGroupChange}
                        error={!!errors.group}
                        helperText={errors.group}
                        
                    />
                </div>
                <div className='send-inputs-column'>
                   <MobileDeviceDropdown
                        className="custom-input"
                        //selectedDeviceProp={deviceId}
                        selectedMobileDeviceProp={selectedDeviceIds} // Pass selectedDeviceIds
                        groupId={groupId}
                        onChange={handleDeviceIdChange}
                        error={!!errors.device}
                        helperText={errors.device}
                        
                    />
                </div>
            </div>


            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <TextField
                        sx={{ width: 274 }}
                        id="message-multiline"
                        label="Message"
                        multiline
                        disabled
                        rows={8}
                        value={message}
                        onChange={handleMessageChange}
                        helperText={`${remainingChars} characters left`}
                    />
                </div>
                <div className='send-inputs-column'>
                    <div className='send-inputs-row'>
                        <SendDirectMessageDropdown 
                            onChange={handleMessageSelect}
                            error={!!errors.message}
                            helperText={"SMS Template is required"}
                        />
                    </div>
                    <div className='send-inputs-row'>
                        <div className='send-inputs-column send-inputs-column__left'>    
                            <TextField 
                                id="lowerdeltatime" 
                                label="Deltatime L-bound (sec)" 
                                variant="outlined" 
                                type="number" 
                                required 
                                inputProps={{ min: "1", step: "10" }}
                                onChange={(e) => {
                                    setLowerdeltatime(e.target.value)
                                    setErrors({ ...errors, hours: validateDigitInput(e.target.value) });
                                    }
                                }
                                error={!!errors.lowerdeltatime}
                                helperText={errors.lowerdeltatime}
                            />
                        </div>

                        <div className='send-inputs-column'>
                        <TextField 
                                sx={{ width: 240 }}
                                id="upperdeltatime" 
                                label="Deltatime U-bound (sec)" 
                                variant="outlined" 
                                type="number" 
                                required 
                                inputProps={{ min: "2", step: "10" }}
                                onChange={(e) => {
                                    setUpperdeltatime(e.target.value)
                                    setErrors({ ...errors, hours: validateDigitInput(e.target.value) });
                                    }
                                }
                                error={!!errors.upperdeltatime}
                                helperText={errors.upperdeltatime}
                            />
                        </div>
                    </div>
                    <div className='send-inputs-row'>
                        <div className='send-inputs-column send-inputs-column__left'>
                            <TextField 
                                sx={{ width: 240 }}
                                id="runs" 
                                label="Runs" 
                                variant="outlined" 
                                type="number" 
                                required 
                                inputProps={{ min: "1", step: "1" }}
                                onChange={(e) => {
                                    setRuns(e.target.value)
                                    setErrors({ ...errors, runs: validateDigitInput(e.target.value) });
                                }}
                                error={!!errors.runs}
                                helperText={errors.runs}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className='send-inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>create</Button>
            </div>
            <FormSubmittedModal open={isModalOpen} onRefresh={handleModalClose} />
        </Stack>
    );
}