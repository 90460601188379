import * as React from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SmsCounterP2A from "../smsCounterP2A/SmsCounterP2A";
import './P2ADashboard.scss';


export default function P2ADashboard() {

    //const [rows, setRows] = React.useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const fetchData = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/getCampaignStats.php');
            if (response.ok) {
                const data = await response.json();
                //setRows(data.data);
                setFetchedData(data.data);
                console.log(data)
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    React.useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const handleRefresh = () => {
        fetchData();
        setRefreshCounter((prevCount) => prevCount + 1);
    }

    const rows = fetchedData.map((row, index) => {
        return { id: index, ...row };
    });

    const columns = [
        { field: 'is_running', headerName: 'Status', width: 100,
            valueGetter: (params) => { 
                const running = params.row.is_running;
                if (running === '1') {
                    return '1';
                } else {
                    return '0';
                }
            },
            renderCell: (params) => {
                const running = params.row.is_running;
                if (running === '1') {
                    return <PlayCircleOutlineIcon style={{ color: 'green' }} />;
                } else {
                    return <StopCircleIcon style={{ color: 'red' }} />;
                }
            }
        },
        { field: 'campaignname', headerName: 'Campaign', width: 250 },
        { field: 'created_at', headerName: 'Date', type: 'number', width: 160 },
        { field: 'OUT', headerName: 'OUT', type: 'number', width: 100 },
        { field: 'IN', headerName: 'IN', type: 'number', width: 100 },
        { field: 'LEFT', headerName: 'LEFT', type: 'number', width: 100 },
        { 
        field: 'conversion', 
        headerName: 'conversion', 
        headerAlign: 'right',
        width: 200,
        valueGetter: (params) => {
            const out = parseFloat(params.row.OUT);
            const smsIn = parseFloat(params.row.IN);
            if (out === 0) {
                return "N/A";
            }
            return ((smsIn / out) * 100).toFixed(2) + '%';
        },
        renderCell: (params) => (
            <div style={{ textAlign: 'right', width: '100%' }}>
                {params.value}
            </div>
        )
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <SmsCounterP2A key={refreshCounter} />
            <Button variant="outlined" className="refresh-btn" onClick={handleRefresh}>refresh</Button>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}