import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// import { useNavigate } from 'react-router-dom';
import P2AGroupsCrowdDropdown from "../p2aGroupsCrowdDropdown/P2AGroupsCrowdDropdown";





export default function GroupsAdd() {
    const [name, setName] = useState("");
    const [crowdId, setCrowdId] = useState("");
    const [info, setInfo] = useState("");

    const handleCrowdChange = (id) => {
        setCrowdId(id);
    };

    // const navigate = useNavigate();
  

    const handleSubmit = async () => {
        const formData = new FormData();

        formData.append('name', name);
        formData.append('crowdid', crowdId);
        formData.append('info', info);

        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/addGroup.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                // navigate('/p2a/receiver');
                setName("");
                setCrowdId("");
                setInfo("");
              } else {
                
              }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };

  
    return (
      <Stack>
        <h2 className='header'>ADD Groups</h2>
        <div className='inputs-wrapper'>
            <TextField id="name" label="Name" variant="outlined" required onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='inputs-wrapper'>
            <TextField
                sx={{ width: 274 }}
                id="info"
                label="Info"
                multiline
                rows={4}
                onChange={(e) => setInfo(e.target.value)}
            />
        </div>
        <div className='inputs-wrapper'>
            <P2AGroupsCrowdDropdown className="custom-input" value={crowdId} onChange={handleCrowdChange}/>
        </div>
        <div className='inputs-wrapper'>
            <Button variant="outlined" onClick={handleSubmit}>save</Button>
        </div>
      </Stack>
    );
}


