import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const GroupsCrowdDropdown = ({ onChange, value }) => {

  const [crowd, setCrowd] = useState([]);
  // const [selectedGroup, setSelectedGroup] = useState('');

  useEffect(() => {
        const fetchCrowd = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listCrowd.php');
            const data = await response.json();
            if (data && data.data) {
                setCrowd(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        }; 

        fetchCrowd();
  }, []);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedCr = crowd.find(p => p.id === selectedId)?.name;

        // setSelectedGroup(selectedId);

        if (onChange) {
        onChange(selectedId, selectedCr);;
        }
    };

    return (
        <FormControl sx={{ width: 234 }}>
            <InputLabel id="crowd-select-label">Crowd</InputLabel>
            <Select
                labelId="crowd-select-label"
                id="crowd-select"
                label="Crowd"
                value={value}
                onChange={handleChange}
            >
                {crowd.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                    {group.name}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GroupsCrowdDropdown;