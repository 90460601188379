import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const GoipProviderDropdown = ({ onChange, value, error, helperText }) => {

  const [providers, setProviders] = useState([]);
  // const [selectedProvider, setSelectedProvider] = useState('');

    useEffect(() => {
        const fetchProviders = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listProviders_v2.php');
            const data = await response.json();
            console.log('provider data ' + data)
            console.log('provider data.data ' + data.data)
            if (data && data.data) {
            setProviders(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        }; 

        fetchProviders();
    }, []);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedProv = providers.find(p => p.id === selectedId)?.prov;

        // setSelectedProvider(selectedId);

        if (onChange) {
        onChange(selectedId, selectedProv);;
        }
    };

    return (
        <FormControl sx={{ width: 234 }} error={error} >
        <InputLabel id="provider-select-label">Provider</InputLabel>
        <Select
            labelId="provider-select-label"
            id="provider-select"
            // value={selectedProvider}
            label="Provider"
            value={value}
            onChange={handleChange}
        >
            {providers.map((provider) => (
            <MenuItem key={provider.id} value={provider.id}>
                {provider.country} ({provider.prov})
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default GoipProviderDropdown;