import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Chip, FormHelperText } from '@mui/material';

const MobileDeviceDropdown = ({ selectedMobileDeviceProp, groupId, onChange, error, helperText }) => {
    const [mobileDevices, setMobileDevices] = useState([]);
    const [selectedMobileDevices, setSelectedMobileDevices] = useState([]);   

    useEffect(() => {
        const fetchMobileDevices = async () => {
        try {
            //console.log('groupId:', groupId)
            //const response = await fetch('https://smsauth.messagemate.at/goip_api/listMobileDevices.php?id=1');
            const response = await fetch(`https://smsauth.messagemate.at/goip_api/listMobileDevices.php?id=${groupId}`);
            const data = await response.json();
            if (data && data.data) {
                setMobileDevices(data.data); 
                if (selectedMobileDeviceProp) {
                    setSelectedMobileDevices(selectedMobileDeviceProp);
                }
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        }; 
        
        if (groupId) {
            fetchMobileDevices();
        }
    }, [groupId, selectedMobileDeviceProp]); // Added selectedMobileDeviceProp to dependency array


    const handleChange = (event) => {
        const { value } = event.target;
        setSelectedMobileDevices(value);
        onChange(value); // Pass the array of selected IDs
    };

    //useEffect(() => {
    //    if (selectedMobileDeviceProp) {
    //        setSelectedMobileDevice(selectedMobileDeviceProp);
    //    }
    //}, [selectedMobileDeviceProp]);   

    //const handleChange = (event) => {
     //   setSelectedMobileDevice(event.target.value);
    //    onChange(event.target.value);
    //};   

    return (

        <FormControl sx={{ width: 234 }} error={error}>
        <InputLabel id="mobile-device-select-label">Mobile Device</InputLabel>      
        <Select 
            labelId="mobile-device-select-label"
            id="mobile-device-select"
            multiple
            value={selectedMobileDevices}
            label="Mobile Device"
            onChange={handleChange}
            renderValue={(selected) => (
                <div>
                    {selected.map((value) => (
                        <Chip key={value} label={mobileDevices.find(device => device.id === value)?.device_name || value} />
                    ))}
                </div>
            )}
        >

            {mobileDevices.map((mobileDevice) => (
            <MenuItem key={mobileDevice.id} value={mobileDevice.id}>
                {mobileDevice.device_name}
            </MenuItem>
            ))} 
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
;

export default MobileDeviceDropdown;
