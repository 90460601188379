import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export default function SmsInboxWithDropdown() {
    const [rows, setRows] = useState([]);
    const [goips, setGoips] = useState([]);
    const [selectedGoip, setSelectedGoip] = useState('');

    useEffect(() => {
        // Fetching the device list for the dropdown
        const fetchGoips = async () => {
            try {
                const response = await fetch('https://smsauth.messagemate.at/goip_api/listRmDevice.php');
                if (response.ok) {
                    const data = await response.json();
                    setGoips(data.data);
                } else {
                    console.error('Failed to fetch devices:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching devices:', error);
            }
        };

        fetchGoips();
    }, []);

    useEffect(() => {
        // Fetching the table data based on the selected device
        const fetchData = async () => {
            if (selectedGoip) {
                try {
                    let url = `https://smsauth.messagemate.at/goip_api/listGoipChannelConfig.php?name=${selectedGoip}`;
                    const response = await fetch(url);
                    if (response.ok) {
                        const data = await response.json();
                        const rowsWithId = data.data.map((row, index) => ({
                            ...row,
                            id: index,
                        }));
                        setRows(rowsWithId);
                    } else {
                        console.error('Failed to fetch data:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
    
        fetchData();
    }, [selectedGoip]);

    rows.map((row, index) => {
        return { id: index, ...row };
      });

    // Handling device selection
    const handleGoipChange = (event) => {
        setSelectedGoip(event.target.value);
    };



    const columns = [
        { field: 'Line ID', headerName: 'Line ID', type: 'number', width: 100 },
        { field: 'Login', headerName: 'Login', width: 90 },
        { field: 'GSM Status', headerName: 'GSM Status', width: 90 },
        { field: 'Operator', headerName: 'Operator', width: 100 },
        { field: 'signal', headerName: 'Signal', type: 'number', width: 60 },
        { field: 'Call Status', headerName: 'Call Status', width: 80 },
        { field: 'Bind Mode', headerName: 'Bind Mode', width: 80 },
        { field: 'Group', headerName: 'Group', width: 120 },
        { field: 'SIM BIND', headerName: 'SIM Bind', type: 'number', width: 90 },
        { field: 'IMEI Using', headerName: 'IMEI Using', type: 'number', width: 160 },
        { field: 'Zone Id', headerName: 'Zone ID', type: 'number', width: 80 },
        { field: 'Zone Name', headerName: 'Zone Name', width: 90 },
        { field: 'Enable', headerName: 'Enable', width: 90 }

    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <FormControl sx={{ width: 300, marginBottom: '30px' }}>
                <InputLabel id="device-select-label">GoIP</InputLabel>
                <Select
                    labelId="device-select-label"
                    id="device-select"
                    value={selectedGoip}
                    label="Device"
                    onChange={handleGoipChange}
                >
                    <MenuItem key="-1" value="-1">
                    All
                    </MenuItem>
                    {goips.map((goip) => (
                        <MenuItem key={goip.name} value={goip.name}>{goip.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}
