import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import ButtonsGroup from "../buttonsGroup/ButtonsGroup";
import SmsCounter from "../smsCounter/SmsCounter";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default function Dashboard({ isAuthenticated, isTest }) {
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    const url = isTest 
        ? `https://smsauth.messagemate.at/api/listTestSMS.php`
        : `https://smsauth.messagemate.at/api/listSMS.php`;

    const fetchData = useCallback(async (id) => {
        const baseURL = `${url}?timespankey=${id}`;

        setLoading(true);

        try {
            console.log('Fetching data...');
            const response = await fetch(baseURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Server responded with status: ${response.status}`);
            }

            const data = await response.json();
            if (data['errorcode'] !== "200") {
                console.error(data['error']);
            } else {
                setFetchedData(data.data);
            }
        } catch (error) {
            console.error("Error fetching SMS:", error);
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        fetchData(activeButton);

        const interval = setInterval(() => fetchData(activeButton), 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, [fetchData, activeButton]);

    const rows = fetchedData.map((row, index) => {
        return { id: index, ...row };
    });

    const columns = [
        { field: 'sid', headerName: 'sid', width: 200 },
        { field: 'name', headerName: 'name', width: 100 },
        { field: 'runs', headerName: 'runs', type: 'number', width: 160 },
        { field: 'allocations', headerName: 'allocations', type: 'number', width: 160 },
        { field: 'received', headerName: 'received', type: 'number', width: 160 },
        { 
            field: 'conversion', 
            headerName: 'conversion', 
            headerAlign: 'right',
            width: 200,
            valueGetter: (params) => {
                const runs = parseFloat(params.row.runs);
                const received = parseFloat(params.row.received);
                if (runs === 0) {
                    return "N/A";
                }
                return ((received / runs) * 100).toFixed(2) + '%';
            },
            renderCell: (params) => (
                <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.value}
                </div>
            )
        },
    ];

    const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
        flexDirection: 'column',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    const CustomLoadingOverlay = () => {
        return (
            <StyledGridOverlay>
                <CircularProgress />
            </StyledGridOverlay>
        );
    };

    return (
        <div style={{ height: 631, width: '100%' }}>
            <SmsCounter/>
            <ButtonsGroup setFetchedData={setFetchedData} setLoading={setLoading} setActiveButton={setActiveButton} url={url} />
            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}
