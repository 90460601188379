import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate} from 'react-router-dom';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function P2ACampaignList() {

    const navigate = useNavigate();
    const [rows, setRows] = React.useState([]);

    const fetchData = async () => {
        try {
            let url = 'https://smsauth.messagemate.at/goip_api/listAllCampaigns.php';
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setRows(data.data);
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleAcivate = (id, status) => {
        fetch(`https://smsauth.messagemate.at/goip_api/setCampaignStatus.php?campaignid=${id}&status=${status}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, status} )
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.success === 1) { 
                fetchData();
            }
        });
    };

    const handleDelete = (id) => {
        fetch(`https://smsauth.messagemate.at/goip_api/delCampaign.php?run_id=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.success === 1) { 
                const newRows = rows.filter(row => row.ref_run_id !== id);
                setRows(newRows);
            }
        });
    };

    function DelButtonModal({ id }) {
        const [open, setOpen] = React.useState(false);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        
        const handleClickOpen = () => {
            setOpen(true);
        };
        
        const handleClose = () => {
            setOpen(false);
        };

        function combinedClickHandler() {
            handleClose();
            handleDelete(id);
        }
        
    
        return (
            <div>
            <Button variant="contained" size="small" color="error" onClick={handleClickOpen}>
                Delete
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Disagree
                </Button>
                <Button onClick={combinedClickHandler} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'campaignname', headerName: 'Campaign', width: 400 },
        { field: 'created_at', headerName: 'Created at', width: 200 },
        
        { field: 'is_active',
            headerName: 'Change Status',
            width: 150,
            renderCell: (params) => {
                const status = params.row.is_active;
                if (status === '1') {
                    return(
                        <Button variant="outlined" size="small" color="error" onClick={() => handleAcivate(params.row.id, 0)}>Deactivate</Button>
                    );
                } else {
                    return (
                        <Button variant="outlined" size="small" onClick={() => handleAcivate(params.row.id, 1)}>Activate</Button>
                    );
                }
            }
        },
        { field: 'ref_run_id',
            headerName: 'Actions',
            width: 180,
            renderCell: (params) => {
                return (
                <>
                    <DelButtonModal id={params.row.ref_run_id} />
                </>
                );
            }
        }
            
    ];	        

     return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            pageSizeOptions={[10, 20, 50]}
            // checkboxSelection
            />
        </div>
        );
};
