import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const MobileGroupsDropdown = ({ selectedMobileGroupProp, onChange, error, helperText }) => {

    const [MobileGroups, setMobileGroups] = useState([]);
    const [selectedMobileGroups, setSelectedMobileGroups] = useState('');

    useEffect(() => {
        const fetchMobileGroups = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listMobileGroups.php');
            const data = await response.json();
            console.log('provider data ' + data)
            console.log('provider data.data ' + data.data)
            if (data && data.data) {
            setMobileGroups(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        }; 

        fetchMobileGroups();
    }, []);

    useEffect(() => {
        if (selectedMobileGroupProp) {
            setSelectedMobileGroups(selectedMobileGroupProp);
        }
    }, [selectedMobileGroupProp]);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedGroup = MobileGroups.find(p => p.id === selectedId)?.name;

        setSelectedMobileGroups(selectedId);

        if (onChange) {
        onChange(selectedId, selectedGroup);;
        }
    };

    return (
        <FormControl sx={{ width: 234 }} error={error}>
        <InputLabel id="mobile-group-select-label">Mobile Group</InputLabel>
        <Select
            labelId="mobile-group-select-label"
            id="mobile-group-select"
            value={selectedMobileGroups}
            label="Mobile Group"
            onChange={handleChange}
        >
            {MobileGroups.map((MobileGroup) => (
            <MenuItem key={MobileGroup.id} value={MobileGroup.id}>
                {MobileGroup.name}
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default MobileGroupsDropdown;