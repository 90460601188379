import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';
// import './NavigationMenuP2A.scss'

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const open1 = Boolean(anchorEl1);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl1(null);
    };

    return (
        <nav className='main-nav'>
        <Button component={Link} to="/index">
            Home
        </Button>
        <Button component={Link} to="/p2p/dashboard">
            Dashboard
        </Button>
        <Button
            id="basic-button-1"
            aria-controls={open1 ? 'basic-menu-1' : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? 'true' : undefined}
            onClick={handleClick1}
        >
            Statistics
        </Button>
        <Menu
            id="basic-menu-1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-1',
            }}
        >
            <MenuItem component={Link} to="/p2p/daily-revenue" onClick={handleClose}>Daily Revenue</MenuItem>
            <MenuItem component={Link} to="/p2p/monthly-revenue" onClick={handleClose}>Monthly Revenue</MenuItem>
            <MenuItem component={Link} to="/p2p/sid" onClick={handleClose}>SID</MenuItem>
        </Menu>

        <div className="logout-button-wrapper">
            <span className="username"><strong>{user}</strong></span>
            <LogoutButton onLogout={onLogout} />
        </div>
        </nav>
    );
}
