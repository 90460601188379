import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import P2AReceiverProviderDropdown from "../p2aReceiverProviderDropdown/P2AReceiverProviderDropdown";
import P2AReceiverGroupDropdown from "../p2aReceiverGroupDropdown/P2AReceiverGroupDropdown";
// import '../batchAdd/BatchAdd.scss';




export default function P2AReceiverEdit() {
    const [receiverId, setReceiverId] = useState("")
    const [no, setNo] = useState("");
    const [name, setName] = useState("");
    const [groupId, setGroupId] = useState("");
    const [receierGroupId, setReceiverGroupId] = useState("");
    const [info, setInfo] = useState("");
    const [number, setNumber] = useState("");
    const [providerId, setProviderId] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const handleProviderChange = (id) => {
      setProviderId(id);
    };
    const handleGroupChange = (id) => {
        setGroupId(id);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://smsauth.messagemate.at/goip_api/getReceiver.php?id=${id}`);
                const result = await response.json();

                if (result.errorcode === '200'){
                    const data = result.data;

                    setReceiverId(data.receiverid);
                    setNo(data.no);
                    setName(data.name);
                    setNumber(data.number);
                    setReceiverGroupId(data.recvgroupid);
                    setGroupId(data.groupid);
                    setInfo(data.info);
                    setProviderId(data.providerid);
                }



            } catch (error) {
                console.error("There was an error fetching the data", error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [id]);
  

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('receiverid', receiverId)
        formData.append('no', no);
        formData.append('name', name);
        formData.append('number', number);
        formData.append('providerid', providerId);
        formData.append('info', info);
        formData.append('recvgroupid', receierGroupId);
        formData.append('groupid', groupId);

        console.log(formData);
    
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/editReceiver.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              console.log(data);
              if (data.success) {
                navigate('/p2a/receiver');
              } else {
                // error
              }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };
  
    return (
        <Stack>
            <h2 className='header'>Receiver EDIT</h2>
            <div className='inputs-wrapper'>
                <TextField id="no" label="No" variant="outlined" value={no} required onChange={(e) => setNo(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField id="name" label="NAME" variant="outlined" value={name} required onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <P2AReceiverGroupDropdown className="custom-input" value={groupId} onChange={handleGroupChange}/>
            </div>
            <div className='inputs-wrapper'>
                <TextField id="info" label="Info" variant="outlined" value={info} required onChange={(e) => setInfo(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField id="number" label="Number" variant="outlined" value={number} required onChange={(e) => setNumber(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <P2AReceiverProviderDropdown className="custom-input" value={providerId} onChange={handleProviderChange}/>
            </div>
            <div className='inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>save</Button>
            </div>
        </Stack>
    );
}


